/* CustomWizardStepper.css */

.progress-bar::-webkit-progress-value {
  background: #3691b2; /* Change this to your desired color */
  border-radius: 10px;
}

.progress-bar::-webkit-progress-bar {
  background-color: #f3f3f3;
  border-radius: 10px;
}

.wizard-container {
  width: 300px;
}

.wizard-steps .wizard-step {
  padding: 10px;
  border-radius: 4px;
  background-color: #edf2f7;
  color: #a0aec0;
}

.wizard-step-active {
  background-color: #4299e1;
  color: #ffffff;
}

.wizard-step-completed {
  background-color: #f7fafc;
  color: #2d3748;
}

.wizard-step-completed .wizard-step-day {
  color: #4a5568;
}

.wizard-step-day {
  font-size: 14px;
}

.wizard-step-destination {
  font-size: 16px;
  font-weight: bold;
}
