.card {
  position: relative;
  overflow: hidden;
  transition: transform 0.2s ease;
}

.card:hover {
  cursor: pointer;
}

.card.hovered {
  transform: scale(1.1);
  z-index: 2;
}

.card img {
  transition: transform 0.2s ease;
}

.card.hovered img {
  transform: scale(1.1);
}
