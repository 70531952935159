@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .headerLink {
    @apply tw-cursor-pointer tw-opacity-75 tw-transition tw-hover:tw-opacity-100;
  }
  /* .link {
    @apply tw-relative tw-cursor-pointer tw-text-lg tw-font-semibold tw-before:absolute tw-before:inset-x-0 tw-before:bottom-0 tw-before:h-0.5
        tw-before:origin-left tw-before:scale-x-0 tw-before:transform tw-before:rounded-bl tw-before:bg-cyan-400
        tw-before:transition-all tw-before:duration-200 tw-hover:before:scale-x-100;
  } */
  .sec-text {
    @apply tw-text-xl tw-font-semibold tw-text-[#d3ecf7];
  }
}
