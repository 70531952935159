.container {
  overflow: hidden;
}

.bird {
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells-new.svg);
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;
}

.bird--one {
  animation: fly-cycle 1s steps(10) infinite -0.5s;
}

.bird--two {
  animation: fly-cycle 0.9s steps(10) infinite -0.5s;
}

.bird--three {
  animation: fly-cycle 1.25s steps(10) infinite -0.25s;
}

.bird--four {
  animation: fly-cycle 1.1s steps(10) infinite -0.5s;
}

.bird-container {
  position: absolute;
  top: 20%;
  left: -10%;
  transform: scale(0) translateX(-10vw);
  will-change: transform;
}

.bird-container--one {
  animation: fly-right-one 15s linear infinite 0;
}

.bird-container--two {
  animation: fly-right-one 16s linear infinite 1s;
}

.bird-container--three {
  animation: fly-right-one 14.6s linear infinite 9.5s;
}

.bird-container--four {
  animation: fly-right-one 16s linear infinite 10.25s;
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-right-one {
  0% {
    transform: scale(0.3) translateX(-10vw);
  }
  10% {
    transform: translateY(2vh) translateX(10vw) scale(0.4);
  }
  20% {
    transform: translateY(0vh) translateX(30vw) scale(0.5);
  }
  30% {
    transform: translateY(4vh) translateX(50vw) scale(0.6);
  }
  40% {
    transform: translateY(2vh) translateX(70vw) scale(0.6);
  }
  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.6);
  }
  60% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
}
